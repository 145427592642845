import axios from "axios";
import { TEST_BASE_API, BASE_API } from "./urls";
import {Notify} from "vant";


var Axios = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? TEST_BASE_API : BASE_API,
    timeout: 500000,
});

Axios.interceptors.request.use(
    (config) => {
        config.headers = Object.assign(config.headers ? config.headers : {}, {
            from: "pc",
            lang: "zh",
        });
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        const res = response.data;
        const code = res.code;
        if (code == 200) {
            return res;
        } else if (code == 400) {
            Notify({
                message: "参数错误，请求被拒绝",
                type: 'warning'
            });
            return Promise.reject(res.msg);
        } else {
            // 其他错误
            Notify({
                message: res.msg,
                type: 'warning'
            });
            return Promise.reject(res.msg);
        }
    },
    (error) => {
        Notify({
            message: "error",
            type: 'warning'
        });
        return Promise.reject(error);
    }
);
export default Axios;
