<template>
    <div @click="call" style="position: fixed;bottom: 80px;right: 10px;">
        <img src="@/assets/images/kefu.png">
    </div>
</template>

<script>
    export default {
        name: "callPhone",
        methods:{
            call(){
                window.location.href = 'http://dkt.zoosnet.net/LR/Chatpre.aspx?id=DKT23243724&lng=cn&p=out.zcwipr.com'
            }
        }
    }
</script>

<style scoped>

</style>