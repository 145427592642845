import request from "@/utils/request";

//获取城市
export const getCity = () => {
    return request({
        url: "outUser/getAddressByIp",
        method: "GET"
    });
};

//提交信息
export const addInfo = (data) => {
    return request({
        url: "outUser/add",
        method: "POST",
        data: data
    });
};
//获取验证码
export const getVerification = () => {
    return request({
        url: "verification/getVerificationCode",
        method: "GET"
    });
};
//获取验证码
export const getVerificationCode2 = (phone, name) => {
    return request({
        url: "verification/getVerificationCode2",
        method: "GET",
        phone: phone,
        name: name
    });
};


//验证兑换码
export const verificationCode = (data) => {
    return request({
        url: "verification/verificationCode?verificationCode=" + data,
        method: "GET"
    });
};