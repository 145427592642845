<template>
    <div>
        <img style="width: 90%; margin: 5%;border-radius: 5px;" src="../assets/images/zhengCard.jpg">
        <div style="position: relative;width: 90%; margin: 0 5%;border-radius: 10px;">
            <img style="width: 100%" src="../assets/images/fanCard.png">
            <span style="position: absolute;top: 46%;left: 57%;font-weight: bold">{{cardCode}}</span>
            <span style="width: 100%;text-align: center;color: black;font-size: 12px;display: flex;align-items: center;font-weight: bold;justify-content: center;margin-top: -30px">推荐好友下单，最高可得15元，详情咨询客服</span>
        </div>
        <span style="width: 100%;text-align: center;color: grey;font-size: 12px;display: flex;align-items: center;justify-content: center;margin-top: 20px">请截图扫描二维码输入卡密兑换体检机会</span>
        <van-submit-bar button-text="获取验证码" @submit="onSubmit"/>
        <callPhone></callPhone>
    </div>
</template>

<script>
    import callPhone from "../components/callPhone";
    import axios from 'axios'
    import {getVerificationCode2, getVerificationCode} from "../api/api";
    export default {
        components: {
            callPhone
        },
        name: "Card",
        data() {
            return {
                cardCode: '',
                name: '',
                phone: ''
            }
        },
        created() {
            this.name = this.$route.query.name;
            this.phone = this.$route.query.phone;
            axios.get('https://outapi.zcwipr.com/wxpay/jsapi1?code=' + sessionStorage.getItem('code') + '&outName=' + this.name + '&outMobile=' + this.phone)
                                .then((res) => {
                                    console.log(res)
                                    let data = {
                                        appId:res.data.data.appId,
                                        timeStamp:res.data.data.timeStamp,
                                        nonceStr:res.data.data.nonceStr,
                                        package:res.data.data.package,
                                        signType:"MD5",
                                        paySign:res.data.data.sign
                                    }
                                    this.pay(data)
                                })
        },
        methods: {
            onSubmit() {
                axios.get('https://outapi.zcwipr.com/verification/getVerificationCode2?name='+ this.name +'&phone=' + this.phone).then((res) => {
                                    console.log(res)
                    this.cardCode = res.data.msg
                })
            },
            pay(data) {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    this.onBridgeReady(data);
                }
            },
            onBridgeReady(data) {
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": data.appId,     //公众号名称，由商户传入
                        "timeStamp": String(data.timeStamp),         //时间戳，自1970年以来的秒数
                        "nonceStr": data.nonceStr, //随机串
                        "package": data.package,
                        "signType": "MD5",         //微信签名方式:
                        "paySign": data.paySign,    //微信签名
                    }, function (res) {
                        if (res.err_msg.indexOf("ok") != -1) {
                            alert("支付成功，请点击右下角获取验证码")
                        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            alert("支付取消")
                        } else {
                            alert("支付失败")
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>
